import { press_kit_vector } from "assets";
import Footer from "components/Footer";
import SiteNavbar from "components/Navbar/Navbar";
import SocialMedia from "components/SocialMedia";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { notify } from "utilities/notify";

const Presskit = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const [hover, setHover] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const downloadPDF = () => {
    setButtonClicked(true)
    const link = document.createElement("a");
    link.href = "/assets/Presskit.pdf";
    link.setAttribute("download", "Presskit.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    notify("Press kit unlocked! Get ready to share the adventure!", "success");
  };

  useEffect(() => {
    if (buttonClicked) {
      const timer = setTimeout(() => {
        setButtonClicked(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [buttonClicked]);

  return (
    <Container
      className="text-white d-flex flex-column px-0"
      style={{
        minHeight: "100vh",
        overflowX: "hidden",
        background: !medium
          ? "black"
          : "linear-gradient(to right, #010101 10%, #202020 40%, #3e3e3e 100%)",
      }}
      fluid
    >
      <SocialMedia />
      <Container fluid className="px-0">
        <SiteNavbar />
      </Container>

      <Container
        fluid
        className="flex-grow-1 position-relative px-0"
        style={{ minHeight: "92vh" }}
      >
        <Row
          className="justify-content-center align-items-center"
          style={{ minHeight: "50vh", position: "relative" }}
        >
          <Col md={4} className="text-center position-relative">
            <div
              style={{
                padding: "40px",
                display: "inline-block",
                position: "relative",
              }}
            >
              <img
                src={press_kit_vector}
                alt="Press Kit Vector"
                style={{
                  display: "block",
                  width: !medium ? "200px" : "",
                  transform: "translate(-20%, 0%)",
                  margin: "initial",
                  zIndex: 1,
                }}
              />
              <h3
                className="big-text"
                style={{
                  position: "absolute",
                  top: !medium ? "50%" : "50%",
                  left: "50%",
                  fontSize: !medium ? "17.73px" : "40px",
                  transform: "translate(30%, -50%)",
                  color: "white",
                  fontWeight: "bold",
                  zIndex: 2,
                  textShadow: "5px 8px 6px rgba(0, 0, 0, 0.9)",
                }}
              >
                PRESS KIT
              </h3>
            </div>
          </Col>
          {medium && (
            <Col md={4} className="text-center" style={{ marginLeft: "8px" }}>
              <Button
                className="px-5 py-3 position-relative"
                style={{
                  width: "311px",
                  border: "none",
                  borderRadius: 0,
                  background:
                    "linear-gradient(90deg, #161616 0%, #1b1b1b 100%)",
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={downloadPDF}
              >
                {hover && (
                  <div
                    className={`position-absolute cursor-pointer ${buttonClicked ? "button-clicked-interaction" : "button-hover-interaction"}`}
                    style={{
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: buttonClicked ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.3)",
                    }}
                  ></div>
                )}
                <span
                  className="small-text"
                  style={{
                    textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
                    color: "#fff",
                  }}
                >
                  download pdf
                </span>
              </Button>
            </Col>
          )}
        </Row>

        <Row className={`${!medium ? "" : "mt-4"}`}>
          <Col md={12} className="text-center">
            <div
              style={{
                width: !medium ? "279px" : "1000px",
                margin: "0 auto",
                textAlign: "left",
                padding: !medium ? "24px 24px" : "0",
              }}
            >
              <h3 className="big-text" style={{ marginBottom: "40px" }}>
                Press Kit
              </h3>
              <p className="small-text exo-2" style={{ lineHeight: "1.6" }}>
                Download our official logos, <a href="/sky-gallery" target="_blank">game screenshots</a>, and trailers for
                use in media coverage or investor presentations. <br />{" "}
                <span style={{ fontWeight: "bold" }}>
                  Download our press kit
                </span>{" "}
                to get everything you need to share our story with the world.
              </p>
            </div>
          </Col>
        </Row>

        {!medium && (
          <div
            className="d-flex justify-content-center mb-5"
            style={{ marginTop: "auto" }}
          >
            <Button
              onClick={downloadPDF}
              className="px-5 py-2 py-lg-4"
              style={{
                width: !medium ? "181px" : "350px",
                fontSize: !medium ? "11px" : "20px",
                fontWeight: "bold",
                lineHeight: "28px",
                letterSpacing: "1.5px",
                margin: "16px 0",
                border: "none",
                borderRadius: 0,
                background: "linear-gradient(90deg, #1f1715 0%, #272727 100%)",
              }}
            >
              <span
                className="small-text bebas-neue"
                style={{
                  textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
                  color: "#fff",
                }}
              >
                download
              </span>
            </Button>
          </div>
        )}
      </Container>

      <Container fluid className="px-0">
        <Footer />
      </Container>
    </Container>
  );
};

export default Presskit;
