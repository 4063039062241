import { blue_squirrel_studios_logo } from "assets";
import React from "react";
import { Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { MdCopyright } from "react-icons/md";

const Footer = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const footerLinks = [
    { id: 1, title: "Wishlist", link: "/wishlist" },
    { id: 2, title: "News", link: "/news" },
    { id: 3, title: "Sky Gallery", link: "/sky-gallery" },
    { id: 4, title: "Presskit", link: "/press-kit" },
  ]

  return (
    <Container
      fluid
      className="px-0"
      style={{
        background: "linear-gradient(90deg, #000000 0%, #303030 46.3%, #000000 100%)",
        color: "#fff",
      }}
    >
      <Container
        fluid
        className="d-flex flex-column"
      >
        <Row className="align-items-center position-relative">
          {medium && (
            <div className="position-absolute">
              <Image
                src={blue_squirrel_studios_logo}
                style={{ width: "55px", height: "32px", marginLeft: "32px" }}
              />
            </div>
          )}

          <div className={`text-center ${!medium ? 'py-4' : 'py-5'}`} style={{ zIndex: 2 }}>
            {footerLinks.map((footerLink, index) => {
              return (
                <a
                  href={footerLink.link}
                  className={`small-text exo-2 text-light-gray text-white ${!medium ? index === 0 ? "me-3" : index === 3 ? "ms-3" : "mx-3" : 'mx-5'}`}
                  style={{ textDecoration: "none" }}
                >
                  {footerLink.title}
                </a>
              )
            })}
          </div>
        </Row>
        <hr className="my-0" style={{ borderColor: "#696969" }} />
        <div className="small-text exo-2 text-light-gray text-center py-4">
          <MdCopyright size="16" /> Blue Squirrel Studios
        </div>
      </Container>
    </Container>
  );
};
export default Footer;
